*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@import url("https://cdn.plyr.io/3.7.8/plyr.css");
:root {
  --text-color: #212529;
  --button-color: #794bc4;
  --border-color: #c4c4c4;
  --border-radius: 10px;
  --max-width: 600px;
  --text-light-gray: #8a96a3;
  --plyr-color-main: #794bc4;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#app {
  /* width: 100%; */
  /* height: 100vh; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
a {
  text-decoration: none;
  display: block;
}
a:hover {
  text-decoration: underline;
}
img {
  width: 100%;
  height: 100%;
}
