.card_wrapper {
  color: var(--text-color);
  /* width: 600px; */
  max-width: var(--max-width);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  padding: 0.4rem;

  font-size: 16px;
}
.card_container {
  display: flex;
}
.post_owner_img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}
.post_header_title {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.post_header_onwer_name {
  display: flex;
  align-items: center;
}
.post_header_owner_username {
  font-size: 0.7rem;
  align-self: start;
  color: var(--text-light-gray);
}
.post_header_title a {
  color: var(--text-color);
}
.post_header_title .username_verified_icon {
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
}
/* Post Data styles */
.post_data {
  padding-left: 0.8rem;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

.post_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
}
.post_description_text {
  padding: 0.5rem 0;
  /* max-width: var(--max-width); */
}
.post_link {
  padding: 1rem 0;
}
.post_inner_card {
  /* max-width: var(--max-width); */
}
.post_inner_card_content {
  cursor: pointer;
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color);
}
.post_inner_card_content img {
  width: 100%;
  max-width: 600px;
}
.post_inner_card_data {
  padding: 1rem;
}
.post_inner_card_img {
  height: "auto";
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
}

.post_inner_card_description {
  padding-top: 0.5rem;
  color: var(--text-light-gray);
}

.post_inner_resource_link {
  padding: 1rem 0;
  width: 100%;
  max-width: 20rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.comment_button {
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
  font-size: medium;
  border: none;
  /* margin: 1rem 0; */
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 10px;
  cursor: pointer;
  background: #794bc4;
  color: white;
}
.comment_button:hover {
  background: #794bc4cc;
}
.video_thumb {
  position: relative;
}
.playIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  background-color: rgba(0, 0, 0, 0.336);
  width: 50px;
  height: 50px;
  border-radius: 100%;
  padding: 0.5rem;
}
.inline-link {
  display: inline-flex;
}
